import Doctor from '@/assets/icons/doctor.svg'
import Clinic from '@/assets/icons/clinic.svg'
import Procedure from '@/assets/icons/procedure.svg'
import Return from '@/assets/icons/return.svg'
import Exam from '@/assets/icons/exam.svg'
import Telemedicine from '@/assets/icons/telemedicine.svg'
import Appointment from '@/assets/icons/appointment.svg'
import Whatsapp from '@/assets/icons/whatsapp-icon.svg'
import moment from 'moment'
import HealthPlan from '@/assets/icons/health-plan.svg'
import Financial from '@/assets/icons/financial.svg'
import Description from '@/assets/icons/new-description.svg'
import Product from '@/assets/icons/product.svg'
import Surgical from '@/assets/icons/surgical.svg'

export default {
  props: {
    content: Object,
    data: Object,
    hasProcedure: Function
  },
  render() {
    return (
      <div class="tooltip-patient">
        <div class="row">
          <div class="col-12">
            <span class="text-truncate tooltip-text patient-name">{this.$props.content.title}</span>
            <span class="tooltip-text ml-2">
              {
                this.$props.data.patient.birthday 
                ? `(${moment().diff(moment(this.$props.data.patient.birthday), 'years')})` 
                : '(Sem data cadastrada)'
              }
            </span>
            <span class="tooltip-text ml-2 whatsapp-link"> {this.$props.data.patient.cellphone ?
              <a href={`https://api.whatsapp.com/send?phone=55${this.$props.data.patient.cellphone.replace(/\D/g, '')}`} target="_blank">
                <Whatsapp width="28" height="28" />
              </a>
              : ''}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="d-flex">
            {this.$props.data.professional ?
              <span>
                <Doctor width="24" height="25"/>
                <span class="tooltip-text info ml-1 text-truncate">{this.$props.data.professional.name}</span>
              </span>
              : ''}
          </div>
        </div>
        <div class="row ml-0 mr-0">
          <div class="d-flex">
            {this.$props.data.room ?
              <span >
                <Clinic viewBox="0 0 26 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="#305BF2"/>
                <span class="tooltip-text info text ml-1 text-truncate">{this.$props.data.room.name}</span>
              </span>
              : ''}
          </div>
        </div>
        <div class="row">
          <div class="d-flex">
            {this.$props.data.health_plan ?
              <span>
                <HealthPlan viewBox="0 0 26 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="#305BF2" />
                <span class="tooltip-text info ml-1 text-truncate">{this.$props.data.healthPlan.fantasy_name}</span>
              </span>
              : ''}
          </div>
        </div>
        <div class="row">
          <div class="d-flex">
            {this.$props.data.total_value ?
              <span>
                <Financial viewBox="0 0 26 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="#305BF2" />
                <span class="tooltip-text info ml-1 text-truncate">{' R$' + this.$props.data.total_value}</span>
              </span>
              : ''}
          </div>
        </div>
        <span class="border-bt"></span>
        <div class="row">
          <div class="d-flex">
            {this.$props.data.appointment_items ?
              <span >
                
              {this.$props.data.appointment_items.map(appointmentItem => {
                return (
                  <div class='border-bt'>
                    <div class="tooltip-text info text-truncate align-items-center h-24p">
                      { appointmentItem.item?.type === 'TELEMEDICINE' ? <Telemedicine width="24" height="25" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="#305BF2" /> : '' }
                      { appointmentItem.item?.type === 'EXAM' ? <Exam width="24" height="25" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="#305BF2" /> : '' }
                      { appointmentItem.item?.type === 'PROCEDURE' ? <Procedure width="24" height="25" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="#305BF2" /> : '' }
                      { appointmentItem.item?.type === 'RETURN' ? <Return width="24" height="25" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="#305BF2" /> : '' }
                      { appointmentItem.item?.type === 'APPOINTMENT' ? <Appointment width="24" height="25" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="#305BF2" /> : '' }
                      { appointmentItem.item?.type === 'SURGICAL' ? <span class='surgical-blue'><Surgical width="24" height="25" fill="#305BF2" /></span> : '' }
                      { appointmentItem.item_type?.includes('Product') ? <Product width="24" height="25" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="#305BF2" /> : '' }
                      {appointmentItem.item?.name?.substring(0, 25) }
                      {appointmentItem.item?.name.length > 25 ? '...' : '' }
                    </div>
                    <div >
                      <span class="tooltip-text"><HealthPlan stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="#305BF2" />{appointmentItem?.health_plan?.fantasy_name ?? appointmentItem?.health_plan?.company_name}</span>
                    </div>
                  </div>
                )
              })}
              </span> : ''}
          </div>
        </div>
        <div class="row">
          <div class="d-flex">
            {this.$props.data.description ?
              <span>
                <Description viewBox="0 0 26 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="#305BF2" />
                <span class="tooltip-text description info">
                  {this.$props.data.description.substring(0, 200) + "\n" }
                  { this.$props.data.description.length > 200 ? '...' : '' }
                  </span>
              </span>
              : ''}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            {(this.$props.hasProcedure(this.$props.data, 'PROCEDURE') ?
              <div class="fc-icon">
                <Procedure />
              </div>
              : '')}

            {(this.$props.hasProcedure(this.$props.data, 'RETURN') ?
              <div class="fc-icon">
                <Return />
              </div>
              : '')}

            {(this.$props.hasProcedure(this.$props.data, 'EXAM') ?
              <div class="fc-icon exam">
                <Exam />
              </div>
              : '')}

            {(this.$props.hasProcedure(this.$props.data, 'TELEMEDICINE') ?
              <div class="fc-icon">
                <Telemedicine />
              </div>
              : '')}

            {(this.$props.hasProcedure(this.$props.data, 'APPOINTMENT') ?
              <div class="fc-icon">
                <Appointment class="appointment" />
              </div>
              : '')}

            {(this.$props.hasProcedure(this.$props.data, 'SURGICAL') ?
              <div class="fc-icon">
                <Surgical viewBox="0 0 24 24" class="surgical" />
              </div>
            : '')}

            {(this.$props.data.status === 'EXAMINATING'
              ?
              <div class="fc-icon">
                <svg viewBox="0 0 24 24" fill="#0C1D59" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 13H8.06189C8.55399 16.9463 11.9204 20 16 20C20.4183 20 24 16.4183 24 12C24 7.58172 20.4183 4 16 4C11.9204 4 8.55399 7.05369 8.06189 11H2C2 10.4477 1.55228 10 1 10C0.447715 10 0 10.4477 0 11V12V13C0 13.5523 0.447715 14 1 14C1.55228 14 2 13.5523 2 13ZM11.1696 15.4502C10.6471 14.7448 10.2613 13.9024 10.0932 13H11C11.5523 13 12 12.5523 12 12C12 11.4477 11.5523 11 11 11H10.0932C10.2614 10.0976 10.6471 9.25526 11.1696 8.54984C11.8971 7.5678 12.7813 6.99122 13.4851 6.81529C14.0884 6.66446 14.5237 6.78701 14.9099 7.24405C15.4071 7.83238 16 9.2091 16 12C16 14.791 15.4071 16.1677 14.9099 16.756C14.5237 17.2131 14.0884 17.3356 13.4851 17.1848C12.7813 17.0088 11.8971 16.4323 11.1696 15.4502ZM16.4928 6.01994C17.3995 7.13959 18 9.05937 18 12C18 14.9406 17.3995 16.8604 16.4928 17.98C19.576 17.7294 22 15.1478 22 12C22 8.85221 19.576 6.27057 16.4928 6.01994Z" />
                </svg>
              </div>
              : '')}
            {(this.$props.data.status === 'DILATING'
              ?
              <div class="fc-icon">
                <svg viewBox="0 0 24 24" fill="#0C1D59" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C5 4 1 12 1 12C1 12 5 20 12 20C19 20 23 12 23 12C23 12 19 4 12 4ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM12 8.00001C9.79086 8.00001 8 9.79087 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79087 14.2091 8.00001 12 8.00001Z" />
                </svg>
              </div>
              : '')}
            {(this.$props.data.type === 'Reagendamento'
              ?
              <div class='fc-icon'>
                <svg viewBox="0 0 13 16" fill="#525C7A" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.79336 1.63232C9.79336 1.21811 9.45757 0.882324 9.04336 0.882324C8.62914 0.882324 8.29336 1.21811 8.29336 1.63232V2.15573H4.69378V1.63232C4.69378 1.21811 4.35799 0.882324 3.94378 0.882324C3.52957 0.882324 3.19378 1.21811 3.19378 1.63232V2.15573H2.02596C0.908376 2.15573 0 3.06039 0 4.1793V6.72618V13.0943C0 14.2132 0.908376 15.1179 2.02596 15.1179H10.9577C12.0752 15.1179 12.9836 14.2132 12.9836 13.0943V6.72618V4.1793C12.9836 3.06039 12.0752 2.15573 10.9577 2.15573H9.79336V1.63232ZM11.4836 5.97618V4.1793C11.4836 3.89146 11.2495 3.65573 10.9577 3.65573H9.79336V4.17947C9.79336 4.59368 9.45757 4.92947 9.04336 4.92947C8.62914 4.92947 8.29336 4.59368 8.29336 4.17947V3.65573H4.69378V4.17947C4.69378 4.59368 4.35799 4.92947 3.94378 4.92947C3.52957 4.92947 3.19378 4.59368 3.19378 4.17947V3.65573H2.02596C1.73415 3.65573 1.5 3.89146 1.5 4.1793V5.97618H11.4836ZM1.5 7.47618H11.4836V13.0943C11.4836 13.3821 11.2495 13.6179 10.9577 13.6179H2.02596C1.73415 13.6179 1.5 13.3821 1.5 13.0943V7.47618Z" />
                  <path d="M10.7499 2.54199H2.24352C1.57238 2.54199 1.02832 2.75921 1.02832 3.02716V6.42335C1.02832 6.6913 1.57238 6.90852 2.24352 6.90852H10.7499C11.421 6.90852 11.9651 6.6913 11.9651 6.42335V3.02716C11.9651 2.75921 11.421 2.54199 10.7499 2.54199Z" />
                </svg>
              </div>
              : '')}
            {(this.$props.data.type === 'Encaixe'
              ?
              <div class='fc-icon'>
                <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM6 2.15789C3.87806 2.15789 2.15789 3.87806 2.15789 6C2.15789 8.12194 3.87806 9.8421 6 9.8421C8.12194 9.8421 9.8421 8.12194 9.8421 6C9.8421 3.87806 8.12194 2.15789 6 2.15789ZM1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6ZM6.26664 3.23584C6.26664 2.9216 6.0204 2.66687 5.71664 2.66687C5.41288 2.66687 5.16664 2.9216 5.16664 3.23584V5.64963C5.16664 5.80053 5.22459 5.94525 5.32773 6.05195L6.72773 7.50022C6.94252 7.72242 7.29076 7.72242 7.50555 7.50022C7.72034 7.27803 7.72034 6.91778 7.50555 6.69559L6.26664 5.41396V3.23584Z" />
                </svg>
              </div>
              : '')}
          </div>
        </div>
        
      </div>
    )
  }
}